// React
import { ReactElement } from 'react';
// Interfaces
import { Slice } from '@utmb/types/slice-handler';
import { SliceBackgroundView } from '@utmb/types/index';
// Styles
import styles from './section-custom-bg.module.scss';
import { Image } from '../image/Image';
import { Pin } from '../pin/Pin';

interface SectionCustomBgProps extends SliceBackgroundView {
    children: string | React.ReactElement | React.ReactElement[];
}

const SectionCustomBg = (props: Slice<SectionCustomBgProps>): ReactElement => {
    const { id, picture, horizontalAlign, verticalAlign, color, position, children, isDark = false } = props;

    const classes = [styles.main, isDark ? '-is-dark' : ''].filter(Boolean).join(' ');
    const cssProperties: any = {};

    if (color) {
        cssProperties.backgroundColor = color;
    }

    return (
        <div id={id} className={classes} style={{ ...cssProperties }}>
            {children}
            <Pin.Cover xy={{ zIndex: -1 }}>
                <Image objectFit={position} objectPosition={`${horizontalAlign} ${verticalAlign}`} layout="fill" image={picture} />
            </Pin.Cover>
        </div>
    );
};

export default SectionCustomBg;
